{
    
    const sizes = [
        [3840, 2160],
        [1920, 1080],
        [1280, 720],
        [640, 480]
    ];

    const xOffsetFactor = 0.35;
    const yOffsetFactor = xOffsetFactor * 16 / 9;
    
    let pathsMorphed = 0;
    
    const images = document.getElementsByClassName('background-image');
    let windowWidth, imageSize;
    let currentImageNum = 1;
    

    window.addEventListener( 'resize', positionAll );

    function positionAll() {

        windowWidth = window.innerWidth;

        if ( windowWidth < 700 ) {
            imageSize = 3;
        }
        else if ( windowWidth < 1400 ) {
            imageSize = 2;
        }
        else if ( windowWidth < 2300 ) {
            imageSize = 1;
        }
        else {
            imageSize = 0;
        }
        
        setIMG( images[ 1 ], currentImageNum );
        
        document.getElementsByClassName( 'container' )[ 0 ].style.height = windowWidth * 9 / 16 + 'px';
        
        const logoPosition = {
            x: sizes[ 0 ][ 0 ] / 2 * xOffsetFactor,
            y: sizes[ 0 ][ 1 ] / 2 * yOffsetFactor
        }
        const logo = document.getElementById('logo');
        logo.setAttribute(
            'transform', 
            `translate(${ logoPosition.x } ${ logoPosition.y })`
        );
        
        const menu = document.getElementById( 'menu' );
        
        menuPosition = {
            x: windowWidth * 0.81,
            y: ( windowWidth * 9 / 16 ) * 0.61
        }
        
        if (windowWidth < 440) {
            menuPosition.x = windowWidth * 0.65
            menuPosition.y = (windowWidth * 9 / 16) * 0.6
        }
        else if ( windowWidth < 580 ) {
            menuPosition.x = windowWidth * 0.76
            menuPosition.y = (windowWidth * 9 / 16) * 0.55
        }
        if ( windowWidth > 2400 ) {
            menuPosition.x = windowWidth * 0.82
            menuPosition.y = (windowWidth * 9 / 16) * 0.62
        }
        menu.setAttribute(
            'style',
            `transform: translate(${menuPosition.x}px, ${menuPosition.y}px)`
        );
    }
    
    positionAll();


    let deferredImageNum = 1;
    let transitioningIMG = false;

    function setBackgroundImage( imageNum ) {


        setIMG( images[ 0 ], imageNum );

        // Start transition
        const duration = 2000;
        const delay = 500 + ( pathsMorphed === 7 ? 0 : 1000 );
        const easing = 'easingCircularOut';

        KUTE.fromTo( images[ 1 ], { opacity: 1 }, { opacity: 0 }, {
            duration: duration,
            delay: delay,
            easing: easing,
            complete: () => {
                setIMG(images[ 1 ], imageNum);
                images[1].style.opacity = 1;  
                transitioningIMG = false; 
                if ( deferredTransition ) {                  
                    deferredTransition(deferredImageNum );
                }                                        
            }
        }).start();

        transitioningIMG = true;
    }

    function setIMG( imageNode, num ) {
        imageNode.setAttribute('srcset', '');
        imageNode.setAttribute('src', `img/twiggy-bilbao-${ num }-${ sizes[ imageSize ][ 0 ] }.jpg`);
    }

    const tweens = [];

    for (let i = 1; i <= 7; i++) {
        tweens.push(KUTE.to('#path' + i, { path: '#path' + i + 'b' }, {
            duration: 2000, // 2000
            morphPrecision: 30,
            morphIndex: 40,
            easing: 'easingCircularInOut',
            complete: () => pathsMorphed = i
        }));
    }

    function morph() {
        var now = window.performance.now();
        const lag = 200;

        for (let i = 0; i < 7; i++) {
            tweens[i].start(now + lag);
        }

        morphed = true;
    }


    //=============================//
    //=============================//
    //=========== MENU ============//
    //=============================//
    //=============================//

    const menuItemCount = 4;

    for (let i = 1; i <= menuItemCount; i++) {
        const item = document.getElementById( 'enlace-' + i );
        item.addEventListener( 'click', openSection );
    }

    const boxes = document.getElementsByClassName( 'recuadro' );

    let deferredTransition = null;
    
    let currentBoxNum;
    
    function openSection( event ) {

        let clickedElement;

        if ( event.target.nodeName === 'A' ) {
            clickedElement = event.target.parentElement;
        }
        else {
            clickedElement = event.target;
        }

        const newBoxNum = clickedElement.id.split('-')[1];
        
        if (history) {
            const linkText = clickedElement.innerText;
            const uriText = linkText.replace(/\s/, '-').toLowerCase();
            history.replaceState( null, null, uriText );
        }



        swapBox( newBoxNum );

        // Image transition
        if ( !transitioningIMG ) {
            deferredTransition = null;
            changeImage( newBoxNum );
        } else {
            deferredImageNum = newBoxNum;
            deferredTransition = changeImage;
        }

    }

    function changeImage( newNum ) {

        if ( newNum != currentImageNum ) {
            currentImageNum = newNum;
            const newImage = new Image();
            newImage.onload = transitionImages;
            newImage.src = `img/twiggy-bilbao-${newNum}-${sizes[imageSize][0]}.jpg`;
            //console.log(newImage.src)
                
        }
    }

    function transitionImages( event ) {
        setBackgroundImage( currentImageNum );
    }

    function swapBox( newBoxNum ) {

        if ( currentBoxNum ) {
            hideBox( currentBoxNum );            
        }

        showBox( newBoxNum );

        currentBoxNum = newBoxNum;
    }

    const boxTweenOptions = {
        delay: ( pathsMorphed === 7 ) ? 0 : 1000,
        duration: 2000,
        easing: 'easingExponentialInOut'
    }
    function hideBox( boxNum ) {
        KUTE.to( '#recuadro-' + boxNum, { opacity: 0 }, boxTweenOptions ).start();
    }

    let morphed = false;
    function showBox( boxNum ) {
        if ( !morphed ) {
            morph();
        }
        KUTE.to('#recuadro-' + boxNum, { opacity: 1 }, boxTweenOptions ).start();
    }
    

    //=============================//
    //=============================//
    //========= HORARIO ===========//
    //=============================//
    //=============================//

    const hoursHuman = {
        winter: [
            ['16:00', '23:00'],     // Domingo
            ['10:00', '23:00'],                           // Lunes  
            ['10:00', '23:00'],                           // Martes  
            ['10:00', '24:00'],                           // Miércoles  
            ['10:00', '1:00'],                           // Jueves  
            ['10:00', '2:30'],      // Viernes                          
            ['13:00', '2:30']      // Sábado  
        ],
        summer: [
            null,                        // Domingo
            ['10:00', '23:00'],                        // Lunes
            ['10:00', '23:00'],                        // Martes
            ['10:00', '24:00'],                        // Miércoles
            ['10:00', '1:00'],                        // Jueves
            ['10:00', '2:30'],  // Viernes
            ['16:00', '2:30']   // Sábado
        ]
    };

    const hours = {
        winter: [
            [ [ '00:00', '02:30' ], [ '16:00', '23:00' ] ],     // Domingo
            [ null, [ '10:00', '23:00' ] ],                     // Lunes  
            [ null, [ '10:00', '23:00' ] ],                     // Martes  
            [ null, [ '10:00', '24:00' ] ],                     // Miércoles  
            [ null, [ '10:00', '24:00' ] ],                     // Jueves  
            [ [ '00:00', '01:00' ], [ '10:00', '24:00' ] ],     // Viernes                          
            [ [ '00:00', '02:30' ], [ '13:00', '24:00' ] ]      // Sábado  
        ],
        summer: [
            [['00:00', '02:30'], null ],                     // Domingo
            [ null, [ '10:00', '23:00' ] ],                     // Lunes
            [ null, [ '10:00', '23:00' ] ],                     // Martes
            [ null, [ '10:00', '24:00' ] ],                     // Miércoles
            [ null, [ '10:00', '24:00' ] ],                     // Jueves
            [ [ '00:00', '01:00' ], [ '10:00', '24:00' ] ],     // Viernes
            [ [ '00:00', '02:30' ], [ '16:00', '24:00' ] ]      // Sábado
        ]
    }

    const format = 'HH:mm';
    const offsetHours = 0;
    const offsetDays = 0;

    const now = moment().tz('Europe/Madrid');
    now.add(offsetHours, 'hours');
    // now.subtract(testLessDays, 'days');
    const day = now.day();
    const month = now.month();
    const hour = now.hour();
    //console.log('day: ' + day);
    //console.log('hour: ' + hour);

    // Set hours according to season
    let currentHours = [];
    let currentHoursHuman = [];
    if (month === 6 || month === 7) {
        currentHours = hours.summer;
        currentHoursHuman = hoursHuman.summer;
    }
    else {
        currentHours = hours.winter;
        currentHoursHuman = hoursHuman.winter;
    }

    // Mostrar horarios
    for (let day = 0; day < currentHoursHuman.length; day++) {
        const hours = currentHoursHuman[day];
        let cell = document.getElementById('hora-apertura-' + day);
        cell.textContent = hours[0];
        cell = document.getElementById('hora-cierre-' + day);
        cell.textContent = hours[1];
    }

    if (currentHoursHuman[0]) {
        document.getElementById('guion-domingo').textContent = '-';
    } else {
        document.getElementById('guion-domingo').textContent = 'Cerrado';
    }

    addClass(document.getElementById('dia-' + day), 'hoy');


    // Calcular apertura y cierre
    const currentDayHours = currentHours[day];

    let aperturaMadrugada, aperturaDia, cierreMadrugada, cierreDia;

    aperturaDia = moment(currentDayHours[1][0], format);
    cierreDia = moment(currentDayHours[1][1], format);
    aperturaDia.add(offsetDays, 'days');
    cierreDia.add(offsetDays, 'days');
    //console.log('cierre day: ' + cierreDia.day())
    //console.log(aperturaDia.hour() + ' - ' + cierreDia.hour())

    if ( currentDayHours[ 0 ] ) {
        aperturaMadrugada = moment(currentDayHours[0][0], format);
        cierreMadrugada = moment(currentDayHours[0][1], format);
        aperturaMadrugada.add( offsetDays, 'days' );
        cierreMadrugada.add( offsetDays, 'days' );
        //console.log(aperturaMadrugada.hour() + ' - ' + cierreMadrugada.hour())
    }

    const openNode = document.getElementById('open');

    if ( isOpen() ) {
        openNode.textContent = 'Abierto ahora';
    }
    else if ( ( now < aperturaDia || now > cierreMadrugada ) && aperturaDia ) {
        openNode.textContent = 'Abre a las ' + aperturaDia.format( format );
    }
    else {
        openNode.textContent = 'Abre mañana a las ' + currentHours[ (day + 1) % 7 ][ 1 ][ 0 ];
    }


    // User interaction and animation
    let hoursOpen = false;
    const horariosHeight = document.getElementById( 'horarios' ).clientHeight;

    const hoursDownTween = KUTE.fromTo('#horarios', { top: -horariosHeight }, { top: 0 }, {
        duration: 300,
        easing: 'easingExponentialInOut'
    });
    const hoursUpTween = KUTE.fromTo('#horarios', { top: 0 }, { top: -horariosHeight }, {
        duration: 300,
        easing: 'easingExponentialInOut'
    });

    const toggleHours = (e) => {
        e.stopPropagation();
        if (!hoursOpen) {
            hoursOpen = true;
            hoursDownTween.start();
        }
        else {
            hoursOpen = false;
            hoursUpTween.start();
        }
    };

    const closeHours = () => {
        if (hoursOpen) {
            hoursOpen = false;
            hoursUpTween.start();
        }
    }

    openNode.addEventListener('click', toggleHours);
    window.addEventListener('click', closeHours);

    const shyHoursTween = KUTE.fromTo('#horarios', { top: -horariosHeight }, { top: -horariosHeight + 100 }, {
        duration: 500,
        delay: 1000,
        repeat: 1,
        yoyo: true,
        easing: 'easingExponentialInOut'
    }).start();

    function isOpen() {
        return ( 
            now.isBetween(aperturaMadrugada, cierreMadrugada) ||
            now.isBetween(aperturaDia, cierreDia) 
        )
    }



    //========= HELPERS ===========//
    
    function removeClass(el, className) {
        if (el.classList) {
            el.classList.remove(className);
        }
        else {
            el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    function addClass( el, className ) {
        if (el.classList)
            el.classList.add(className);
        else
            el.className += ' ' + className;
    }

}
